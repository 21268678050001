<template>
    <div class="circle-progress-container">
        <div class="progress-text">
            <slot v-if="!$slots.default"> {{ progress }} </slot>
            <slot v-else />
        </div>
        <svg :width="diameter" :height="diameter">
            <g :transform="'translate(' + radius + ',' + radius + ')'">
                <!-- Track -->
                <circle :fill="trackColor" :r="radius"></circle>
                <!-- Colored fill -->
                <path :fill="progressColor" :d="sectorPath"></path>
                <!-- Inner circle -->
                <circle :fill="innerColor" :r="radius - border"></circle>
            </g>
        </svg>
    </div>
</template>
  
<script>
export default {
    props: {
        diameter: {
            type: Number,
            default: 100,
        },
        strokeWidth: {
            type: Number,
            default: 0,
        },
        progress: {
            type: Number,
            required: true
        },
        border: {
            type: Number,
            default: 5
        },
        progressColor: {
            type: String,
            default: '#00aaff'
        },
        trackColor: {
            type: String,
            default: '#ccc'
        },
        innerColor: {
            type: String,
            default: '#ffffff'
        }
    },
    data() {
        return {
            radius: this.diameter / 2,
            endAngle: Math.PI * 2,
        };
    },
    computed: {
        sectorPath() {
            const startAngle = -Math.PI / 2;
            const endAngle = startAngle + this.endAngle * this.progress;

            const start = this.polarToCartesian(0, 0, this.radius, endAngle);
            const end = this.polarToCartesian(0, 0, this.radius, startAngle);

            const largeArcFlag = endAngle - startAngle <= Math.PI ? '0' : '1';

            return [
                'M', 0, 0,
                'L', start.x, start.y,
                'A', this.radius, this.radius, 0, largeArcFlag, 0, end.x, end.y,
                'Z'
            ].join(' ');
        },
    },
    methods: {
        polarToCartesian(centerX, centerY, radius, angleInRadians) {
            const x = centerX + (radius * Math.cos(angleInRadians));
            const y = centerY + (radius * Math.sin(angleInRadians));
            return { x, y };
        },
    },
};
</script>
  
<style scoped>
svg {
    display: block;
}
.progress-text {
    position: absolute;
    left: 50%;
    top: 50%;
    width: 100%;
    transform: translate(-50%,-50%);
    text-align: center;
}

</style>